@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('/assets/css/font.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root{
    --primary-color: #05041B;
  }

body{
    background-color: #05041B;
    overflow: hidden;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    width: 658px;
    height: 369px;
    position: relative;
}

.main-container::after {
    background-image: url('/assets/images/top-side.webp');
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: inline-block;
    width: 75%;
    height: 100%;
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
}

.logo {
    position: relative;
}

.logo-image {
    width: 110px;
    height: 110px;
    -webkit-box-shadow: 0px 0px 44px 0px rgba(78,198,253,1);
    -moz-box-shadow: 0px 0px 44px 0px rgba(78,198,253,1);
    box-shadow: 0px 0px 44px 0px rgba(78,198,253,1);
    border-radius: 25px;
}

.title {
    font-size: 68px;
    font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    letter-spacing: -1px;
    margin-top: 24px;
    font-family: 'Aspekta', 'sans-serif';
    background: linear-gradient(180deg, #FFF 0%, #ADADAD 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.description {
    font-size: 16px;
    font-family: 'Inter', 'sans-serif';
    color: #C3C2DC;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 16px 0 32px 0;
}

.join-waitlist-bar {
    position: relative;
}

.join-waitlist-input {
    width: 435px;
    height: 52px;
    padding: 14px 0 14px 16px;
    outline: none;
    color: #fff;
    border-radius: 999px;
    border: 1px solid rgba(223, 225, 231, 0.10);
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(4.5px);
    transition: all .3s;
}

.join-waitlist-input::placeholder {
    color: #A4ACB9;
}

.join-waitlist-input:focus {
    border: 1px solid #4F4FFF;
    box-shadow: 0px 0px 0px 3px rgba(160, 160, 255, 0.20);
}

.join-waitlist-button {
    position: absolute;
    top: 50%;
    right: 8px;
    padding: 8px 16px;
    border: 1px solid rgba(223, 225, 231, .1);
    transform: translateY(-50%);
    border-radius: 999px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(13, 4, 29, 0.20) 64.10%, rgba(70, 70, 70, 0.20) 100%); 
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.084px;
}

.brief-overview-container {
    width: 1032px;
    height: 664px;
    border-radius: 12px;
    margin-top: 45px;
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, .12);
    background-color: rgba(5, 4, 27, .5);
    box-shadow: 0 2px 31px 0 rgba(100, 96, 198, .15);
    -webkit-box-shadow: 0 2px 31px 0 rgba(100, 96, 198, .15);
    -moz-box-shadow: 0 2px 31px 0 rgba(100, 96, 198, .15);
}

.brief-overview {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, .12);
    box-shadow: 0 2px 94px 0 rgba(100, 96, 198, .04);
    -webkit-box-shadow: 0 2px 94px 0 rgba(100, 96, 198, .04);
    -moz-box-shadow: 0 2px 94px 0 rgba(100, 96, 198, .04);
    background-image: url('/public/assets/images/bottom-side.png');
    background-repeat: no-repeat;
    background-position-y: 56px;
    background-color: #05041B50;
    background-size: 200%;
}

.brief-overview-header {
    width: 100%;
    height: 40px;
    background-color: #0D0D1280;
    border: 1px solid #ffffff04;
    backdrop-filter: blur(12px);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: inline-flex;
    padding: 9px 452px 9px 24.75px;
    align-items: center;
    gap: 396.25px;
}

.tool-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.brief-overview-header-tool {
    width: 8px;
    height: 8px;
    border-radius: 10px;
}

.tool-red {
    background-color: #FC544B;
}

.tool-orange {
    background-color: #F9B430;
}

.tool-green {
    background-color: #24C43D;
}

.header-link {
    width: 94px;
    height: 22px;
    border-radius: 5px;
    padding: 2px 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, .1);
    backdrop-filter: blur(14px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-link-text {
    font-family: 'Inter', 'sans-serif';
    color: #FFF;
    font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 180% */
    letter-spacing: -0.06px;
}

.brief-main-container {
    margin: 37px 55px;
    width: 149px;
    height: 67px;
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.brief-main-bag {
    width: 67px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(115, 102, 255, 0.10);
}

.brief-main-bag span {
    font-family: 'Inter', 'sans-serif';
    font-size: 12px;
    color: #fff;
}

.brief-main-title {
    font-family: 'Aspekta', 'sans-serif';
    font-weight: 500;
    font-size: 22px;
    letter-spacing: -1px;
    background: linear-gradient(to bottom, #ffffff, #adadad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.brief-image {
    margin-top: 5%;
    width: 50%;
}

@media (max-width: 1050px) {
    .center {
        margin-top: 30%
    }

    .main-container::after {
        width: 100%;
        background-size: 200%;
        background-position-x: center;
    }

    .brief-overview-container {
        width: 626px;
        height: 600px;
    }

    .brief-image {
        width: 626px;
        margin-top: 10%;
    }
}

@media (max-width: 610px) {
    .center {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
    
    .logo-image {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 744px) {
    .center {
        width: 344px;
        height: 293px;
        margin-top: 174px;
    }

    .title {
        font-size: 32px;
        margin-top: 32px;
    }

    .description {
        font-size: 12px;
    }

    .brief-overview-container {
        width: 330px;
        height: 316px;
    }

    .brief-image {
        width: 350px;
        margin-top: 25%;
    }

    .brief-overview-header {
        padding: 4.746px 140.125px 4.239px 13.052px;
        align-items: center;
        gap: 111.252px;

    }

    .header-link {
        width: 33px;
        height: 10px;
    }

    .header-link-text {
        font-size: 5.274px;
    }

    .join-waitlist-input {
        width: 324px;
        height: 52px;
        padding: 14px 8px 14px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
    }
}

